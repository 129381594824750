import React, { useState } from 'react';
import styled from 'styled-components';
import Countdown from '../components/Countdown';
import axios from 'axios';
import { Form, Input, Button } from '../StyledInputs';

const HomeContainer = styled.div`
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ImageContainer =  styled.div`
  width: 100%;
  height: 30vh;
  background-image: url('/images/whole30-austin-bbq-edition.webp');
  background-size: cover;
  background-position: center;
`;

const Home: React.FC = () => {
  const [name, setName] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('email'); // 'email' or 'otp'
  const [email,  setEmail] = useState('');


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  };

  const handleSendOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/send-otp', { email, name });
      setStep('otp');
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleVerifyOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/verify-otp', { email, otp });
      const { token } = response.data;
      localStorage.setItem('token', token);
      window.location.href = '/add-activity';
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <HomeContainer>
      <ImageContainer />
      <Countdown />
      {step === 'email' ? (
        <Form onSubmit={handleSendOTP}>
          <Input
            type="name"
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleEmailChange}
          />
          <Button type="submit">Nom, nom, nom</Button>
        </Form>
      ) : (
        <Form onSubmit={handleVerifyOTP}>
          <Input
            type="text"
            placeholder="Enter one-time password"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button type="submit">Verify</Button>
        </Form>
      )}
    </HomeContainer>
  );
};

export default Home;