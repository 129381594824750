const theme = {
  colors: {
    background: '#f5f7fa',
    card: '#FFFFFF',
    text: '#000000',
    textSecondary: '#333333',
    primary: '#007AFF',
    secondary: '#FF2D55',
    border: '#E5E5EA',
    white: '#FFFFFF',
    greyText: '#555555',
  },
  fontSizes: {
    small: '14px',
    medium: '16px',
    large: '20px',
    xlarge: '24px',
    xxlarge: '32px',
  },
  spacing: {
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '32px',
  },
  borderRadius: {
    small: '12px',
    medium: '16px',
    large: '20px',
  },
};

export default theme;