import React from 'react';
import styled from 'styled-components';
import BottomNav from './BottomNav';
import { isAuthenticated } from '../utils';

const LayoutContainer = styled.div`
  background-color: #f5f7fa;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const BottomNavContainer = styled.div`
  margin-top: auto;
`;


const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LayoutContainer>
      {children}
      <BottomNavContainer>
        {isAuthenticated() && <BottomNav />}
      </BottomNavContainer>
    </LayoutContainer>
  );
};

export default Layout;