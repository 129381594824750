import React, { useState, useEffect, useCallback } from 'react';
import { getChallenges, createActivity, getActivities } from '../services/api';
import { Input, Button, Select, Form } from '../StyledInputs';
import { Activity, ActivityType } from '../types';
import styled from 'styled-components';
import Card from '../components/Card';

const AddActivityContainer = styled.div`
  padding-bottom: 100px;
  overflow-x: hidden; // Prevent horizontal scrolling
  min-width: 100%; // Ensure container takes full width
`;

const PointTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledInput = styled(Input)`
  text-align: center;
  -moz-appearance: textfield; /* Firefox */
  margin: 0 5px; // Add some margin on the sides
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledButton = styled.button`
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const InputLabel = styled.label`
  margin-bottom: 5px;
`;

const AddActivity: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activityType, setActivityType] = useState<ActivityType>(ActivityType.DISH);
  const [challengeId, setChallengeId] = useState<number | null>(null);
  const [previousTotal, setPreviousTotal] = useState<number>(0);
  const [newTotalDistance, setNewTotalDistance] = useState<number>(0);
  const [newAmountDrinks, setNewAmountDrinks] = useState<number>(0);
  const [bbqAmount, setBbqAmount] = useState<number>(0);
  const [sidesAmount, setSidesAmount] = useState<number>(0);

  // Function to fetch challengeId
  const fetchChallengeId = async () => {
    const response = await getChallenges();
    if (response.data.length > 0) {
      return response.data[0].id;
    }
    return null;
  };

  // Function to fetch activities for a given challengeId
  const fetchPreviousTotal = useCallback(async (challengeId: number) => {
    const response = await getActivities(challengeId);
    const totalAmount = response.data.reduce((total: number, activity: Activity) => {
      if (activity.type === ActivityType.DISTANCE && activityType === ActivityType.DISTANCE) {
        return total + activity.amount;
      }

      if (activity.type === ActivityType.DISH && activityType === ActivityType.DISH) {
        return total + activity.amount;
      }

      if (activity.type === ActivityType.DRINK && activityType === ActivityType.DRINK) {
        return total + activity.amount;
      }
      return total;
    }, 0);

    return totalAmount;
  }, [activityType]);

  const fetchData = useCallback(async () => {
    try {
      // First, fetch the challengeId
      const id = await fetchChallengeId();
      if (id) {
        setChallengeId(id);

        const fetchedTotal = await fetchPreviousTotal(id);
        setPreviousTotal(fetchedTotal);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPreviousTotal]);

  useEffect(() => {
    fetchData();
  }, [fetchData]); // only re-fetch when activityType changes

  const calculateDishPoints = (bbq: number, sides: number): number => {
    const bbqPoints = Math.floor(bbq / 0.25);
    const sidesPoints = sides;
    return bbqPoints + sidesPoints;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    switch (activityType) {
      case ActivityType.DISTANCE:
        await createActivity(challengeId, { type: ActivityType.DISTANCE, amount: newTotalDistance });
        setNewTotalDistance(0);
        break;
      case ActivityType.DISH:
        const totalPoints = calculateDishPoints(bbqAmount, sidesAmount);
        await createActivity(challengeId, { type: ActivityType.DISH, amount: totalPoints });
        setBbqAmount(0);
        setSidesAmount(0);
        break;
      case ActivityType.DRINK:
        await createActivity(challengeId, { type: ActivityType.DRINK, amount: newAmountDrinks });
        setNewAmountDrinks(0);
        break;
    }
    fetchData();
  };

  const incrementValue = (setter: React.Dispatch<React.SetStateAction<number>>, increment: number, step: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setter((prev) => Math.round((prev + increment) / step) * step);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<number>>,
    step: number
  ) => {
    const value = e.target.value;
    if (value === '' || value === '-') {
      setter(0);
    } else {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setter(numValue);
      }
    }
  };

  const handleInputBlur = (
    setter: React.Dispatch<React.SetStateAction<number>>,
    step: number
  ) => {
    setter((prev) => Math.round(prev / step) * step);
  };

  const renderForm = () => {
    switch (activityType) {
      case ActivityType.DISTANCE:
        return (
          <>
            <label>Current total distance</label>
            <PointTitle>{previousTotal.toFixed(2)} miles</PointTitle>
            <label>Distance to <b>add</b></label>
            <InputContainer>
              <StyledButton onClick={(e) => incrementValue(setNewTotalDistance, -0.25, 0.25, e)}>-</StyledButton>
              <StyledInput
                type="number"
                value={newTotalDistance === 0 ? '' : newTotalDistance}
                onChange={(e) => handleInputChange(e, setNewTotalDistance, 0.25)}
                onBlur={() => handleInputBlur(setNewTotalDistance, 0.25)}
                step="0.25"
                placeholder="0.00"
              />
              <StyledButton onClick={(e) => incrementValue(setNewTotalDistance, 0.25, 0.25, e)}>+</StyledButton>
            </InputContainer>
          </>
        );
      case ActivityType.DISH:
        return (
          <>
            <label>Current total food points</label>
            <PointTitle>{previousTotal.toFixed(2)} points</PointTitle>
            <InputGroup>
              <InputLabel>Lbs of BBQ to add:</InputLabel>
              <InputContainer>
                <StyledButton onClick={(e) => incrementValue(setBbqAmount, -0.25, 0.25, e)}>-</StyledButton>
                <StyledInput
                  type="number"
                  value={bbqAmount === 0 ? '' : bbqAmount}
                  onChange={(e) => handleInputChange(e, setBbqAmount, 0.25)}
                  onBlur={() => handleInputBlur(setBbqAmount, 0.25)}
                  step="0.25"
                  placeholder="0.00"
                />
                <StyledButton onClick={(e) => incrementValue(setBbqAmount, 0.25, 0.25, e)}>+</StyledButton>
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputLabel>Number of sides to add:</InputLabel>
              <InputContainer>
                <StyledButton onClick={(e) => incrementValue(setSidesAmount, -1, 1, e)}>-</StyledButton>
                <StyledInput
                  type="number"
                  value={sidesAmount === 0 ? '' : sidesAmount}
                  onChange={(e) => handleInputChange(e, setSidesAmount, 1)}
                  onBlur={() => handleInputBlur(setSidesAmount, 1)}
                  step="1"
                  placeholder="0"
                />
                <StyledButton onClick={(e) => incrementValue(setSidesAmount, 1, 1, e)}>+</StyledButton>
              </InputContainer>
            </InputGroup>
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
              <span style={{ textDecoration: 'underline' }}>Points calculation:</span>
              <p>BBQ: {Math.floor(bbqAmount / 0.25)} points</p>
              <p>Sides: {sidesAmount} points</p>
              <p><b>Total: {calculateDishPoints(bbqAmount, sidesAmount)} points</b></p>
            </div>
          </>
        );
      case ActivityType.DRINK:
        return (
          <>
            <label>Current total drink points</label>
            <PointTitle>{previousTotal.toFixed(2)} drinks</PointTitle>
            <label>Drinks to <b>add</b></label>
            <InputContainer>
              <StyledButton onClick={(e) => incrementValue(setNewAmountDrinks, -1, 1, e)}>-</StyledButton>
              <StyledInput
                type="number"
                value={newAmountDrinks === 0 ? '' : newAmountDrinks}
                onChange={(e) => handleInputChange(e, setNewAmountDrinks, 1)}
                onBlur={() => handleInputBlur(setNewAmountDrinks, 1)}
                step="1"
                placeholder="0"
              />
              <StyledButton onClick={(e) => incrementValue(setNewAmountDrinks, 1, 1, e)}>+</StyledButton>
            </InputContainer>
          </>
        );
    }
  };

  return (
    isLoading ? (
      <p>Loading...</p>
    ) : (
      <AddActivityContainer>
        <Form style={{marginTop: '20px'}} onSubmit={handleSubmit}>
          <Select
            value={activityType}
            onChange={(e) => setActivityType(e.target.value as ActivityType)}
            >
            <option value={ActivityType.DRINK}>Drink</option>
            <option value={ActivityType.DISH}>{process.env.REACT_APP_FOOD}</option>
            <option value={ActivityType.DISTANCE}>Distance</option>
          </Select>
          <Card>
            {renderForm()}
            <Button
              type="submit"
              disabled={isLoading || challengeId === null}
              style={{marginTop: '20px'}}
              >Add Activity
            </Button>
          </Card>
        </Form>
      </AddActivityContainer>
    )
  );
};

export default AddActivity;