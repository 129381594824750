import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing.medium} 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const NavItem = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: ${({ theme, $isActive }) => $isActive ? theme.colors.primary : theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
const Icon = styled.span`
  font-size: 18px;
  margin-bottom: 4px;
`;

const BottomNav: React.FC = () => {
  const location = useLocation()
  const currentPath = location.pathname

  return (
    <NavContainer>
      <NavItem to="/add-activity" $isActive={currentPath === '/add-activity'}>
        <Icon>🍺 🍽️ 👟</Icon>
        Add Activity
      </NavItem>
      <NavItem to="/itinerary" $isActive={currentPath === '/itinerary'}>
        <Icon>🗺️</Icon>
        Itinerary
      </NavItem>
      <NavItem to="/leaderboard" $isActive={currentPath === '/leaderboard'}>
        <Icon>📊</Icon>
        Leaderboard
      </NavItem>
    </NavContainer>
  );
};

export default BottomNav;