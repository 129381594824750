import React from 'react';
import Card from './Card';
import { Button } from '../StyledInputs'

interface Challenge {
  id: number;
  name: string;
  date: string;
}

interface ChallengeProps {
  challenges: Challenge[];
  onClick: (challengeId: number) => void;
}

const Challenges: React.FC<ChallengeProps> = ({challenges, onClick }) => {
  return (
    <div>
      {challenges.map((challenge) => (
        <Card
          title={challenge.name}
          key={challenge.id}
        >
          <p style={{marginBottom:'20px'}}>Date: {new Date(challenge.date).toLocaleDateString()}</p>
          <Button onClick={() => onClick(challenge.id)}>
            Load Users and Locations
          </Button>
        </Card>
      ))}
    </div>
  );
};

export default Challenges;