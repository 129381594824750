import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.card};
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const CardInnerContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  width: 100%;
  max-width: 400px;
`;

interface CardProps {
  title?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, children }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <CardInnerContainer>
      {children}
    </CardInnerContainer>
  </CardContainer>
);

export default Card;