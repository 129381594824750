import React, { useCallback, useEffect, useState } from 'react';
import { getChallengeParticipants, getChallenges, getLocations, createLocation, updateLocation } from '../services/api';
import Challenges from '../components/Challenges';
import { Challenge, User, Location } from '../types';
import { StyledTable, StyledThead, StyledTh, StyledTd, Button, Input, Form } from '../StyledInputs';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

const SuperadminDashboard: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  });
  const [challenges, setChallenges] = useState<Challenge[]>([])
  const [locations, setLocations] = useState<Location[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [showCreateLocationForm, setshowCreateLocationForm] = useState<Boolean>(false);
  const [locationName, setLocationName] = useState<string>('');
  const [locationAddress, setLocationAddress] = useState<string>('');
  const [challengeId, setChallengeId] = useState<number | null>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [editingLocationId, setEditingLocationId] = useState<number | null>(null);

  const handleUpdateLocation = useCallback(async (locationId: number) => {
    const locationToUpdate = locations.find(l => l.id === locationId);
    if (!locationToUpdate) return;

    try {
      const response = await updateLocation(locationId, {
        name: locationToUpdate.name ?? undefined,
        address: locationToUpdate.address ?? undefined,
        time: locationToUpdate.time ?? undefined,
        description: locationToUpdate.description ?? undefined
      });
      setLocations(locations.map(l => l.id === locationId ? response.data.location : l));
      setEditingLocationId(null);
    } catch (error) {
      console.error(error);
    }
  }, [locations]);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setLocationAddress(place.formatted_address || '');
      // You can also extract more details like latitude and longitude here
    }
  };

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await getChallenges();
        setChallenges(response.data)
      } catch (error) {
        console.error(error);
      }
    }

    fetchChallenges();
  }, []);

  const handleChallengeClick = useCallback(async (challengeId: number) => {
    const [locationsResponse, usersResponse] = await Promise.all([
      getLocations(challengeId),
      getChallengeParticipants(challengeId)
    ])

    setLocations(locationsResponse.data)
    setUsers(usersResponse.data)
    setChallengeId(challengeId);
  }, [])

  const handleCreateLocation = useCallback(async (e: React.FormEvent, challengeId: number) => {
    e.preventDefault();

    try {
      const response = await createLocation(
        challengeId,
        {
          name: locationName,
          address: locationAddress,
        }
      );

      setLocations(prevLocations => [...prevLocations, response.data.location]);
      setshowCreateLocationForm(false);
      setLocationName('');
      setLocationAddress('');
    } catch (error) {
      console.error(error);
    }
  }, [locationName, locationAddress]);

  useEffect(() => {
    console.log('Updated locations:', locations);
  }, [locations]);

  return (
    <div>
      <Challenges
        challenges={challenges}
        onClick={handleChallengeClick}
      />
      {challengeId && (
        <div>
          <h2 style={{marginTop:'20px'}}>Locations</h2>
          <Button
            onClick={() => setshowCreateLocationForm(true)}
          >
            Add Location
          </Button>
          {showCreateLocationForm && (
            <Form>
              <label>Name</label>
              <Input
                type="string"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
              />
              <label>
                Address
              </label>
              {isLoaded && (
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <Input
                    type="text"
                    placeholder="Enter address"
                    value={locationAddress}
                    onChange={(e) => setLocationAddress(e.target.value)}
                  />
                </Autocomplete>
              )}
              <Button
                onClick={(e) => handleCreateLocation(e, Number(challengeId))}
              >
                Add
              </Button>
            </Form>
          )}
        </div>
      )}
      {locations.length > 0 && (
        <>
          <StyledTable>
            <StyledThead>
              <tr>
                <StyledTh>Time</StyledTh>
                <StyledTh>Name</StyledTh>
                <StyledTh>Address</StyledTh>
                <StyledTh>Description</StyledTh>
                <StyledTh>Actions</StyledTh>
              </tr>
            </StyledThead>
            <tbody>
              {locations.map((location) => (
                <tr key={location.id}>
                  <StyledTd>
                    {editingLocationId === location.id ? (
                      <Input
                        type="time"
                        value={location.time || ''}
                        onChange={(e) => {
                          setLocations(locations.map(l =>
                            l.id === location.id ? {...l, time: e.target.value} : l
                          ));
                        }}
                      />
                    ) : location.time}
                  </StyledTd>
                  <StyledTd>
                    {editingLocationId === location.id ? (
                      <Input
                        type="text"
                        value={location.name}
                        onChange={(e) => setLocations(locations.map(l =>
                          l.id === location.id ? {...l, name: e.target.value} : l
                        ))}
                      />
                    ) : location.name}
                  </StyledTd>
                  <StyledTd>
                    {editingLocationId === location.id ? (
                      <Input
                        type="text"
                        value={location.address}
                        onChange={(e) => setLocations(locations.map(l =>
                          l.id === location.id ? {...l, address: e.target.value} : l
                        ))}
                      />
                    ) : location.address}
                  </StyledTd>
                  <StyledTd>
                    {editingLocationId === location.id ? (
                      <Input
                        type="text"
                        value={location.description || ''}
                        onChange={(e) => setLocations(locations.map(l =>
                          l.id === location.id ? {...l, description: e.target.value} : l
                        ))}
                      />
                    ) : location.description}
                  </StyledTd>
                  <StyledTd>
                    {editingLocationId === location.id ? (
                      <Button onClick={() => handleUpdateLocation(location.id!)}>Save</Button>
                    ) : (
                      <Button onClick={() => setEditingLocationId(location.id!)}>Update</Button>
                    )}
                  </StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
      {users.length > 0 && (
        <>
          <h2 style={{marginTop: '20px'}}>Users</h2>
          <StyledTable>
            <StyledThead>
              <tr>
                <StyledTh>Name</StyledTh>
                <StyledTh>Phone</StyledTh>
                <StyledTh>One-time password</StyledTh>
              </tr>
            </StyledThead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <StyledTd>{user.name}</StyledTd>
                  <StyledTd>{user.phoneNumber}</StyledTd>
                  <StyledTd>{user.otp}</StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
    </div>
  );
};

export default SuperadminDashboard;