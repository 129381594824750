import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './utils';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Layout from './components/Layout';
import Home from './pages/Home';
import AddActivity from './pages/AddActivity';
import Leaderboard from './pages/Leaderboard';
import SuperadminDashboard from './pages/SuperadminDashboard';
import Itinerary from './pages/Itinerary';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={isAuthenticated() ? <Navigate to="/add-activity" replace /> : <Home />} />
            <Route path="/add-activity" element={<AddActivity />} />
            <Route path="/itinerary" element={<Itinerary />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/admin" element={<SuperadminDashboard />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;