import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Libraries, Polyline } from '@react-google-maps/api';
import { getLocations, getChallenges } from '../services/api';
import { Location } from '../types';
import { StyledTable, StyledThead, StyledTh, StyledTd } from '../StyledInputs';
import styled from 'styled-components';

const ItineraryContainer = styled.div`
  padding-bottom: 100px;
  overflow-x: hidden; // Prevent horizontal scrolling
  width: 100%; // Ensure container takes full width
`;

const mapContainerStyle = {
  width: '100vw',
  height: '50vh',
  maxWidth: '100%'
};

const center = {
  lat: 30.2672,
  lng: -97.7431
};

const libraries: Libraries = ['places', 'marker'];

const formatTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
  return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const ResponsiveTable = styled(StyledTable)`
  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      padding: 0.5rem;
    }
    td {
      display: block;
      text-align: right;
      padding: 0.5rem 0;
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }
    }
  }
`;

const MapLink = styled.a`
  color: #0000EE; // Standard link color
  text-decoration: underline;
  word-break: break-word;
  &:hover {
    color: #551A8B; // Visited link color
  }
  &:active {
    color: #FF0000; // Active link color
  }
`;

const Itinerary: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [challengeId, setChallengeId] = useState<number | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: libraries,
  });

  useEffect(() => {
    const fetchChallengeId = async () => {
      try {
        const response = await getChallenges();
        if (response.data.length > 0) {
          setChallengeId(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching challenges:', error);
      }
    };

    fetchChallengeId();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      if (challengeId) {
        const response = await getLocations(challengeId);
        setLocations(response.data);
      }
    };

    fetchLocations();
  }, [challengeId]);

  // New useEffect to create the path
  useEffect(() => {
    if (locations.length > 0) {
      const newPath = locations.map(location => ({
        lat: location.latitude,
        lng: location.longitude
      }));
      setPath(newPath);
    }
  }, [locations]);

  // This useEffect is responsible for adding AdvancedMarkerElement to the map once loaded
  useEffect(() => {
    console.log('Map loaded:', isLoaded);
    console.log('mapRef', mapRef.current);
    console.log('Locations:', locations);
    console.log('Path:', path);
    if (isLoaded && mapRef.current && locations.length > 0) {
      locations.forEach((location, index) => {
        const markerElement = new google.maps.marker.AdvancedMarkerElement({
          position: { lat: location.latitude, lng: location.longitude },
          map: mapRef.current,
          title: location.name,
          content: document.createElement('div'),
        });

        const content = markerElement.content as HTMLElement | null;
        if (content) {
          content.innerText = `${formatTime(location.time ?? '')} ${location.name}`;
          content.style.backgroundColor = "blue";
          content.style.padding = "10px";
          content.style.borderRadius = "5px";
          content.style.color = "white";
        }
      });
    }
  }, [isLoaded, locations, path]);

  const getMapUrl = (address: string) => {
    const encodedAddress = encodeURIComponent(address);
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      return `maps:?q=${encodedAddress}`;
    }
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  };

  return (
    <ItineraryContainer>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          options={{
            mapId: process.env.REACT_APP_GOOGLE_MAPS_ID,
            fullscreenControl: true,
          }}
          onLoad={(map) => {
            mapRef.current = map; // Assign map instance to ref
          }} // No return value, as expected by GoogleMapProps
        >
        {path.length > 0 && (
          <Polyline
            path={path}
            options={{
              strokeColor: "#000000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />
        )}
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}

      <ResponsiveTable>
        <StyledThead>
          <tr>
            <StyledTh>Time</StyledTh>
            <StyledTh>Name</StyledTh>
            <StyledTh>Address</StyledTh>
            <StyledTh>Description</StyledTh>
          </tr>
        </StyledThead>
        <tbody>
          {locations.map((location) => (
            <tr key={location.id}>
              <StyledTd data-label="Time">
                {formatTime(location.time ?? '')}
              </StyledTd>
              <StyledTd data-label="Name">{location.name}</StyledTd>
              <StyledTd data-label="Address">
                <MapLink href={getMapUrl(location.address)} target="_blank" rel="noopener noreferrer">
                  {location.address}
                </MapLink>
              </StyledTd>
              <StyledTd data-label="Description">{location.description}</StyledTd>
            </tr>
          ))}
        </tbody>
      </ResponsiveTable>
    </ItineraryContainer>
  );
};

export default Itinerary;
