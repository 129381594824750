export enum ActivityType {
  DISTANCE = 'DISTANCE',
  DISH = 'DISH',
  DRINK = 'DRINK'
}

export type Activity = {
  id: number;
  type: ActivityType;
  amount: number;
  createdAt: Date;
  participantId: number;
};

export interface Challenge {
  id: number;
  name: string;
  date: string;
}

export interface Location {
  time: string | null;
  id?: number
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  order?: number | null;
  description?: string | null;

}

export interface User {
  id: number
  name: string
  phoneNumber: string
  otp: string
}