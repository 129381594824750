import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getLeaderboard, getChallenges } from '../services/api';
import Card from '../components/Card';

const LeaderboardContainer = styled.div`
  padding-bottom: 180px;
  overflow-x: hidden; // Prevent horizontal scrolling
  width: 100%; // Ensure container takes full width
`;

const LeaderboardTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const LeaderboardList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LeaderboardItem = styled.li`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

const Rank = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 30px;
`;

const UserInfo = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NameAndStats = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Name = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.greyText};
`;

const Stats = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyText};
  margin-top: 4px;
`;

const Points = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

interface LeaderboardEntry {
  userId: number;
  name: string;
  totalPoints: number;
  distancePoints: number;
  dishPoints: number;
  drinkPoints: number;
}

const Leaderboard: React.FC = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);


  useEffect(() => {
    const fetchChallengeId = async () => {
      const response = await getChallenges();
      if (response.data.length > 0) {
        return response.data[0].id;
      }
      return null;
    };

    const fetchLeaderboard = async () => {
      try {
        const challengeId = await fetchChallengeId();
        // Assuming you're fetching the leaderboard for challenge with ID 1
        const response = await getLeaderboard(challengeId);
        setLeaderboard(response.data);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);


  return (
    <LeaderboardContainer>
      <Card>
        <LeaderboardTitle>Leaderboard</LeaderboardTitle>
        <LeaderboardList>
          {leaderboard.map((item, index) => (
            <LeaderboardItem key={index}>
              <Rank>{index + 1}</Rank>
              <UserInfo>
                <NameAndStats>
                  <Name>{item.name}</Name>
                  <Points>{item.totalPoints.toFixed(2)}</Points>
                  <Stats>
                    🍺 {item.drinkPoints.toFixed(2)} · 🍽️ {item.dishPoints.toFixed(2)} · 👟 {item.distancePoints.toFixed(2)}
                  </Stats>
                </NameAndStats>
              </UserInfo>
            </LeaderboardItem>
          ))}
        </LeaderboardList>
      </Card>
    </LeaderboardContainer>
  );
};

export default Leaderboard;