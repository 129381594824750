import { jwtDecode } from 'jwt-decode';

interface User {
  id: string;
  name: string;
  phoneNumber: string;
}

interface DecodedToken extends User {
  exp: number;
}

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decoded = jwtDecode<DecodedToken>(token);
    return decoded.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
};