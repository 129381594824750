import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownContainer = styled.div`
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: ${({ theme }) => theme.spacing.large};
    textt-align: center;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const TimeUnit = styled.div`
  text-align: center;
  padding: 1rem;
  border-radius: 4px;
  min-width: 80px;
`;

const Number = styled.span`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;

const Label = styled.span`
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
`;

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({});

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date('2024-11-02') - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24 + 5),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }

      return timeLeft;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <CountdownContainer>
        <div>
          <h1
            style={{textAlign: 'center', marginTop: '20px'}}
          >
            Whole30 Austin: {process.env.REACT_APP_FOOD} Edition
          </h1>
          <p style={{marginBottom: '20px', textAlign: 'center'}}><b>November 2nd, 2024</b></p>
        </div>
        <TimeDisplay>
          <TimeUnit>
            <Number>{timeLeft.days}</Number>
            <br/>
            <Label>Days</Label>
          </TimeUnit>
          <TimeUnit>
            <Number>{timeLeft.hours}</Number>
            <br/>
            <Label>Hours</Label>
          </TimeUnit>
          <TimeUnit>
            <Number>{timeLeft.minutes}</Number>
            <br/>
            <Label>Minutes</Label>
          </TimeUnit>
          <TimeUnit>
            <Number>{timeLeft.seconds}</Number>
            <br/>
            <Label>Seconds</Label>
          </TimeUnit>
        </TimeDisplay>
    </CountdownContainer>
  );
};

export default Countdown;
