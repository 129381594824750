import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ActivityType } from '../types';

interface User {
  id: string;
  name: string;
  phoneNumber: string;
}

interface DecodedToken extends User {
  exp: number;
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token) as DecodedToken;
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getChallenges = () => api.get('/challenges');

interface createActivityParams {
  type: ActivityType;
  amount: number;
}
export const createActivity = (challengeId: number | null, data: createActivityParams) => api.post('/activities/' + challengeId, data);
export const getActivities = (challengeId: number | null) => api.get('/activities/' + challengeId);
export const getLeaderboard = (challengeId: number) => api.get(`/leaderboard/${challengeId}`);
export const getLocations = (challengeId: number) => (
  api.get('/locations/' + challengeId)
)
export const getChallengeParticipants = (challengeId: number) => (
  api.get('/users/' + challengeId)
)
interface createLocationParams {
  name: string;
  address: string;
  order?: number | null;
}

export const createLocation = (challengeId: number, data: createLocationParams) => (
  api.post('/admin/locations/' + challengeId, data)
)

// Add this interface near the top of the file with your other interfaces
interface UpdateLocationParams {
  name?: string;
  address?: string;
  order?: number | null;
  time?: string;
  description?: string;
}

// Add this function to your existing api.ts file
export const updateLocation = (locationId: number, data: UpdateLocationParams) => (
  api.put(`/admin/locations/${locationId}`, data)
)

export default api;